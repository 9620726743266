import { configureStore } from "@reduxjs/toolkit";
import { productSlice } from "./reducers/productsSlice";
import { usermgmtSlice } from "./reducers/UsermgmtSlice";



export const store = configureStore({
  reducer: {
    productData:productSlice.reducer,
    usersdata: usermgmtSlice.reducer,
   

  },
});