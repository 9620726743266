import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";
import Support from "./pages/Support";
import Dashboard from "./pages/Dashboard";
import Supportchat from "./pages/SupportChat";
import CreateAdmin from "./pages/CreateAdmin";
import CustomerProfile from "./pages/CustomerProfile";
import ProductManagement from "./pages/ProductManagement";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import OrderManagement from "./pages/OrderManagement";
import ManageChat from "./pages/ManageChat";
import AddProduct from "./pages/AddProduct";
import OrderView from "./pages/OrderView";
import ViewProduct from "./pages/ViewProduct";
import ForgotPasswordtwo from "./pages/ForgotPasswordtwo";
import TemporaryBanned from "./pages/TemporaryBanned";
import Active from "./pages/Active";
import ProtectedRoutes from "./Components/ProtectedRoutes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />}/>

          <Route element={<ProtectedRoutes />}>
            <Route path="/ManageChat" element={<ManageChat />}/>
            <Route path="/OrderManagement" element={<OrderManagement />} />
            <Route path="/Otp" element={<LoginOtp />} />      
            <Route path="/ChangePasswordtwo" element={<ForgotPasswordtwo />} />
            <Route path="/UserManagement" element={<UserManagement />} />
            <Route path="/Support" element={<Support />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/CustomerProfile" element={<CustomerProfile />} />
            <Route path="/Supportchat" element={<Supportchat />} />
            <Route path="/CreateAdmin" element={<CreateAdmin />} />
            <Route path="/ProductManagement" element={<ProductManagement />} />
            <Route path="/TemporaryBanned" element={<TemporaryBanned />} />
            <Route path="/Active" element={<Active />} />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/AddNotification" element={<AddNotification />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/OrderView" element={<OrderView />} />
            <Route path="/AddProduct" element={<AddProduct />} />
            <Route path="/ViewProduct/:id" element={<ViewProduct />} />
          </Route>
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
