import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  banOrunbanUser,
  dowanloadBanusers,
  dowanloadxlsv,
  getBannedUser,
  getUserManagement,
} from "../redux/actions/adminAction";
import { toast } from "react-toastify";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";


export default function TemporaryBanned() {
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);
  const [Banuserdata, setBanuserdata] = useState([]);
  const [pagination, setpagination] = useState("");
  console.log(pagination);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const searchData = {
      type: 0,
      page: page,
      limit: limit,
    };

    if (search) {
      searchData.search = search;
    }

    const getdata = async () => {
      const data = await dispatch(getBannedUser(searchData));
      console.log(data);
      if (data?.payload?.status === 200) {
        setBanuserdata(data?.payload?.data?.data?.users);
        setpagination(data?.payload?.data?.data);
      }
    };
    getdata();
  }, [page, limit, flag, search]);

  const handleuprofile = (user) => {
    navigate("/CustomerProfile", {
      state: {
        id: user,
      },
    });
  };

  const handlesearch = (e) => {
    setsearch(e.target.value);
  };

  const handlelimit = (e) => {
    setlimit(e.target.value);
  };


  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(dowanloadBanusers({type:0}));
      const allData = response?.payload?.data?.users;
      const ws = XLSX.utils.json_to_sheet(allData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Temporary Banned</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={3}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={handlesearch}
                  />
                </Form.Group>
              </Col>
              {/* <Col xxl={2} xl={3} lg={3} md={3}>
                <Form.Group>
                  <Form.Control type="date" placeholder="Search.." name="date" /> 
                </Form.Group>
              </Col> */}
              <Col xxl={2} xl={3} lg={3} md={3}>
                {/* <Form.Select aria-label="Default select example">
                  <option>Status</option>
                  <option value="1">Active</option>
                  <option value="2">Banned</option>
                </Form.Select> */}
              </Col>
              <Col
               xxl={7}
               xl={6}
               lg={6}
               md={6}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn" onClick={fetchDataAndExportToExcel}>
                  <Link to="#">DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement">
                  ALL 
                </Link>
              </li>
              <li>
                <Link to="/Temporarybanned" className="active-tab">
                  Temporary Banned 
                </Link>
              </li>
              <li>
                <Link to="/Active">
                  Active 
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {pagination?.currentPage
                  ? 1 + (pagination.currentPage - 1) * limit
                  : 0}{" "}
                - {pagination?.currentPage ? pagination.currentPage * limit : 0}{" "}
                of {pagination?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={handlelimit}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Profile Name </th>
                <th>Phone Number </th>
                <th>Email</th>
                <th>Status</th>
                <th>Profile Action</th>
              </tr>
            </thead>
            {Banuserdata?.map((details, index) => {
              const serialNumber =
                ((page == "1" ? 1 : page) - 1) * limit + index + 1;
              return (
                <tbody>
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleuprofile(details?._id)}
                      >
                        <b>{details?.full_name ? details?.full_name : "N/A"}</b>
                      </p>
                    </td>
                    <td>{`+${details?.country_code ?? ''} ${details?.phone_number ?? ''}`}</td>
                    <td>{details?.email}</td>
                    <td>{details?.is_active === 1 ? "Active" : "Banned"}</td>
                    <td>
                      <div className="select-box">
                        <Form.Select
                          aria-label="Default select example"
                          value={details?.is_active}
                          onChange={async (e) => {
                            const data = await dispatch(
                              banOrunbanUser({
                                userId: details?._id,
                                type: e.target.value,
                              })
                            );
                            console.log(data);
                            if (data?.payload?.status === 200) {
                              toast.success(data?.payload?.message);
                              setflag(!flag);
                            }
                          }}
                        >
                          <option value="1">Active</option>
                          <option value="0">Temporary Banned</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </div>
      </Container>
      <div className="table-pagi">
        <Pagination
          totalstuff={pagination?.totalCount}
          limit={limit}
          setpages={setpage}
        />
      </div>
    </Layout>
  );
}
