import { createSlice } from "@reduxjs/toolkit";
import { getUserManagement } from "../actions/adminAction";


export const usermgmtSlice = createSlice({
    name: "usermgmtSlice",
    initialState:{
        alldata: null,
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
      
        builder.addCase(getUserManagement.fulfilled, (state, action) => {
            state.alldata = action.payload;
            console.log(action?.payload,"action.payload");
        });
    }
    
})


export default usermgmtSlice.reducer;