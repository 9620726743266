import axios from "axios";

export const adminApi = axios.create({
//   baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
  baseURL: "https://just-in-api.bosselt.com/api/admin",
  headers: {
    Authorization: sessionStorage.getItem("token"),
  },
});

adminApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error?.response?.status === 401) {
    // //   setTimeout(() => {
    //     sessionStorage.clear();
    //     window.location.reload(false);
    //     window.location.href = "/";
    // //   }, 1000);
    // }
    return error.response;
  }
);
