import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Users({activeUsers,inactiveUsers}) {

  const data = {
    labels: [`Active Users ${activeUsers}%`, `InActive Users ${inactiveUsers}%`],
    datasets: [
      {
        label: "# of Votes",
        data: [activeUsers,inactiveUsers],
        backgroundColor: ["#209920","#023020"],

        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Doughnut className="gender-chart" data={data} />
    </>
  );
}
