import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  banOrunbanUser,
  dowanloadxlsv,
  getUserManagement,
} from "../redux/actions/adminAction";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

export default function UserManagement() {
  const userdata = useSelector((state) => state.usersdata.alldata);
  console.log(userdata, "userdata");

  const [page, setpages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserManagement({ limit: limit, search: search, page: page }));
  }, [limit, page, search, flag]);

  const handlesearch = (e) => {
    setsearch(e.target.value);
  };

  const handlelimit = (e) => {
    setlimit(e.target.value);
  };

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(dowanloadxlsv());
      const allData = response?.payload?.data?.users;
      const ws = XLSX.utils.json_to_sheet(allData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  const handleuprofile = (user) => {
    navigate("/CustomerProfile", {
      state: {
        id: user,
      },
    });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>User Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={handlesearch}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                {/* <Form.Select aria-label="Default select example">
                  <option>Profile Action</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select> */}
              </Col>
              <Col
                xxl={7}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn" onClick={fetchDataAndExportToExcel}>
                  <Link to="#">DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement" className="active-tab">
                  All
                </Link>
              </li>
              <li>
                <Link to="/TemporaryBanned">Temporary Banned</Link>
              </li>
              <li>
                <Link to="/Active">Active</Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {userdata?.data?.currentPage
                  ? 1 + (userdata?.data?.currentPage - 1) * limit
                  : 0}{" "}
                -{" "}
                {userdata?.data?.currentPage
                  ? Math.min(
                      userdata?.data?.currentPage * limit,
                      userdata?.data?.totalCount
                    )
                  : 0}{" "}
                of {userdata?.data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={handlelimit}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Profile Name </th>
                <th>Phone Number </th>
                <th>Email</th>
                <th>Status</th>
                <th>Profile Action</th>
              </tr>
            </thead>
            {userdata?.data?.users?.map((details, index) => {
              const serialNumber =
                ((page == "1" ? 1 : page) - 1) * limit + index + 1;
              return (
                <tbody>
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleuprofile(details?._id)}
                      >
                        <b>{details?.full_name ? details?.full_name : "N/A"}</b>
                      </p>
                    </td>
                    <td>
                      {details?.phone_number
                        ? `+${details?.country_code ?? ""} ${
                            details?.phone_number ?? ""
                          }`
                        : "N/A"}
                    </td>

                    <td>{details?.email}</td>
                    <td>{details?.is_active === 1 ? "Active" : "Banned"}</td>
                    <td>
                      <div className="select-box">
                        <Form.Select
                          aria-label="Default select example"
                          value={details?.is_active}
                          onChange={async (e) => {
                            const data = await dispatch(
                              banOrunbanUser({
                                userId: details?._id,
                                type: e.target.value,
                              })
                            );
                            console.log(data);
                            if (data?.payload?.status === 200) {
                              toast.success(data?.payload?.message);
                              setflag(!flag);
                            }
                          }}
                        >
                          <option value="1">Active</option>
                          <option value="0">Temporary Banned</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </div>
      </Container>
      <div className="table-pagi">
        <Pagination
          totalstuff={userdata?.data?.totalCount}
          limit={limit}
          setpages={setpages}
          // search={search}
        />
      </div>
    </Layout>
  );
}
