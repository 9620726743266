import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Orders({Active,Completed,Cancel}) {

  const data = {
  labels: [`Active Orders ${Active}%`,`Completed Orders ${Completed}%`,`Cancel Orders ${Cancel}%`],
  datasets: [
    {
      label: "# of Votes",
      data: [Active,Completed,Cancel],
      backgroundColor: ["#209920","	#023020","#32CD32"],

      borderWidth: 1,
    },
  ],
};

   
  return(

 <>
  <Doughnut className="gender-chart" data={data} />
  </>
  )
}
