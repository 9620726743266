import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Modal, Button } from "react-bootstrap";
import { deleteProduct, getProducts } from "../redux/actions/adminAction";
import { useDispatch, useSelector } from "react-redux";
import { FiCloudLightning } from "react-icons/fi";
import Pagination from "../Components/Layout/Pagination";
import { toast } from "react-toastify";

export default function ProductManagement() {
  const data = useSelector((state) => state.productData.list);
  console.log(data);
  const dispatch = useDispatch();

  const [page, setpage] = useState("1");
  const [limit, setlimit] = useState("10");
  const [search, setsearch] = useState("");
  const [showd, setshowd] = useState(false);
  const [flag, setflag] = useState(true);

  const [productID, setproductID] = useState(false);

  useEffect(() => {
    dispatch(getProducts({ search: search, limit: limit, search: search }));
  }, [page, limit, search, flag]);

  const handlesearch = (e) => {
    setsearch(e.target.value);
  };

  const handleDelete = async () => {
    const res = await dispatch(deleteProduct({ productId: productID }));
    console.log(res);
    if (res?.payload?.status === 200) {
      toast.success(res?.payload?.message);
      setshowd(false);
      setflag(!flag);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Product Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={handlesearch}
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/AddProduct">Create New</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserVerification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (page - 1) * limit} -{" "}
                {data?.data?.allProducts?.length + (page - 1) * limit} of{" "}
                {data?.data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Product Name</th>
                <th>Description</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.allProducts?.map((product, index) => {
                const serialNumber =
                  ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      {" "}
                      <Link to={`/ViewProduct/${product?._id}`}>
                        <b>{product?.product_name}</b>
                      </Link>
                    </td>
                    <td className="ad-desc">
                      <p>{product?.desc}</p>
                    </td>
                    <td>{product?.price}</td>
                    <td>{product?.quantity}</td>
                    <td>
                      <Button
                        onClick={() => {
                          setshowd(true);
                          setproductID(product?._id);
                        }}
                        className="account-btn common-colr-btn"
                      >
                        Delete
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="table-pagi">
        <Pagination
          totalstuff={data?.data?.totalPages}
          limit={limit}
          setpages={setpage}
        />
      </div>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Product
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
