

import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminApi } from "../../services/adminapi";

// Forgot Password

export const ForgotPass = createAsyncThunk(
  "ForgotPassword",
  async (details) => {
    const data = await adminApi.post("/auth/forgetpassword", details);
    return data;
  }
);

//  OtpVerify

export const OtpVerify = createAsyncThunk("OtpVerify", async (details) => {
  const {data} = await adminApi.post("/auth/verifyOtp", details);
  return data;
});

// / resetpassword

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (details) => {
    const data = await adminApi.post("/auth/resetPassword", details);
    return data;
  }
);

//   change password

export const Changepassword = createAsyncThunk(
  "Changepassword",
  async (details) => {
    const {data} = await adminApi.patch("/auth/changePassword", details);
    console.log(data);
    return data;
  }
);

//  add product

export const addProduct = createAsyncThunk(
  "addProduct",
  async (details) => {
    const {data} = await adminApi.post("/addProduct", details);
    console.log(data);
    return data;
  }
);

// list product

export const getProducts = createAsyncThunk(
  "getProducts",
  async (details) => {
    const {data} = await adminApi.get(`/getProducts?page=${details?.page}&limit=${details?.limit}&name=${details?.search}`,details);
   
    return data;
  }
);

// =========================================get products====================================

export const getProductbyId = createAsyncThunk(
  "getProductbyId",
  async (details) => {
    const {data} = await adminApi.get(`/getProductById?productId=${details}`, details);
   
    return data;
  }
);
// =========================================delete products====================================

export const deleteProduct = createAsyncThunk(
  "deleteProduct",
  async (details) => {
    const {data} = await adminApi.patch(`/deleteProduct`, details);
   
    return data;
  }
);

// =========================================editProduct======================================

export const editProduct = createAsyncThunk(
  "editProduct",
  async (details) => {
    const {data} = await adminApi.put(`/editProduct`, details);
   
    return data;
  }
);


// ==================================== Usermanagement listing ====================================

export const getUserManagement = createAsyncThunk(
  "getUserManagement",
  async (details) => {
    const {data} = await adminApi.get(`/userManagement?page=${details?.page}&limit=${details?.limit}&name=${details?.search}`);  
    return data;
  }
);


// ===================================== Temporary banned listing ==============================

export const getBannedUser = createAsyncThunk(
  "getUserManagement",
  async (details) => {
    let url  = (`/userManagement?page=${details?.page}&limit=${details?.limit}&type=${details?.type}`);  

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await adminApi.get(url);
    
    return data;
  }
);

// ================================== Active user listing ======================

export const getActiveUsers = createAsyncThunk(
  "getActiveUsers",
  async (details) => {
    let url  = (`/userManagement?page=${details?.page}&limit=${details?.limit}&type=${details?.type}`);  

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await adminApi.get(url);

    return data;
  }
);

  
 // ================== get user profile ===========================

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (details) => {
    const {data} = await adminApi.get(`/userProfile?userId=${details?.userId}`);   
    return data;
  }
);

//==========================   ban and unban user =================

export const banOrunbanUser = createAsyncThunk(
  "banOrunbanUser",
  async (details) => {
    const {data} = await adminApi.put(`/banUnbanUser`,details);   
    return data;
  }
);
  
   // ================= delete user account =============
export const DeleteUser = createAsyncThunk(
  "DeleteUser",
  async (details) => {
    const {data} = await adminApi.patch(`/deleteAccount?userId=${details?.userId}`,);   
    return data;
  }
);

 // ======== dowanload all users =============
export const dowanloadxlsv = createAsyncThunk(
  "dowanloadxlsv",
  async () => {
    const {data} = await adminApi.get(`/userManagement`);   
    return data;
  }
);

// ========== dowanload ban users =============


export const dowanloadBanusers = createAsyncThunk(
  "dowanloadBanusers",
  async (details) => {
    const {data} = await adminApi.get(`/userManagement?=${details?.type}`);   
    return data;
  }
);

// ============ dowanload Active users ===========

export const dowanloadActiveUsers = createAsyncThunk(
  "dowanloadActiveUsers",
  async (details) => {
    const {data} = await adminApi.get(`/userManagement?=${details?.type}`);   
    return data;
  }
);

// =================== get orders Listing ===================

export const getOrdersListing = createAsyncThunk(
  "getOrdersListing",
  async (details) => {
    const {data} = await adminApi.get(`/getOrders?page=${details?.page}&limit=${details?.limit}&name=${details?.search}&status=${details?.status}`);  
    return data;
  }
);

// ========= dowanload orders file ===========

export const dowanloadOrdersCsv = createAsyncThunk(
  "dowanloadOrdersCsv",
  async (details) => {
    const {data} = await adminApi.get(`/getOrders`);  
    return data;
  }
);


// =========  get orders details by id  ==========


export const getOrderById = createAsyncThunk(
  "getOrderById",
  async (details) => {
    const {data} = await adminApi.get(`/getOrderById?orderId=${details?.orderId}`);  
    return data;
  }
);

// =============   chnage order status ===========


export const ChangeOrderStatus = createAsyncThunk(
  "ChangeOrderStatus",
  async (details,) => {
    const {data} = await adminApi.put(`/changeOrderStatus`,details);  
    return data;
  }
);


// get Support listing 

export const getSuports = createAsyncThunk(
  "getSuports",
  async (details) => {
    const {data} = await adminApi.get(`/getSupports?page=${details?.page}&limit=${details?.limit}&search=${details?.search}`);  
    return data;
  }
);

// dowanload support xlsv

export const dowanloadSupport = createAsyncThunk(
  "dowanloadSupport",
  async (details) => {
    const {data} = await adminApi.get(`/getSupports`);  
    return data;
  }
);

// chnage chat status 

export const chatStatusChange = createAsyncThunk(
  "chatStatusChange",
  async (details) => {
    const {data} = await adminApi.post(`/changeSupportStatus`, details);  
    return data;
  }
);

// get support chat 



export const getSupportChat = createAsyncThunk(
  "getSupportChat",
  async (details) => {
    const {data} = await adminApi.get(`/getSupportById?id=${details?.id}`);  
    return data;
  }
);

// get user activity

export const  getActivity = createAsyncThunk(
  "getActivity",
  async(details)=> {
     const {data} = await adminApi.get(`/getActivities?user_id=${details?.user_id}`)
     return data;
  }
)

// ================ get Dashboard api =====================

export const getDashboard = createAsyncThunk(
  "getDashboard",
  async()=> {
    const {data} = await adminApi.get(`/dashboard`);
    return data;
  }
)






