import React, { useEffect } from "react";
import Layout from "../Components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import * as yup from 'yup';
import { Formik, ErrorMessage } from "formik";
// import { Changepassword } from "../Redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify"

import { useNavigate } from "react-router-dom";
import { Changepassword } from "../redux/actions/adminAction";



const validationSchema = yup.object().shape({
  currentPassword: yup.string().required(<span style={{ color: 'red' }}>Required</span>),
  password: yup.string().required(<span style={{ color: 'red' }}>Required</span>),
  confirmpassword: yup.string().oneOf([yup.ref("password"), null], <span style={{ color: 'red' }}>Password Must Match</span>)
  .required(<span style={{ color: 'red' }}>Required</span>),
})

export default function ChangePassword() {

  const dispatch = useDispatch();
  const navigate=useNavigate()
  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <h2>Change Password</h2>
          </div>
          <hr />
        </div>
        <Container fluid>
          <Row className="justify-content-md-center mt-4">
            <Col lg="7" md="auto">
              <Card className="border- p-5 rounded-card customer-form-new">
                <h2 className="text-center">Change Password</h2>
                <Formik initialValues={{ currentPassword:"", password:"", confirmpassword:"" }}
                validationSchema={validationSchema}
                onSubmit={ async (values) => {
                  console.log(values)

                  try{
                 const data = await dispatch(Changepassword({
                  oldPassword:values?.currentPassword,
                  newPassword:values?.password
                  
                 }));
                   console.log(data);
                   if (data?.payload?.success) {
                    toast
                    .success(data?.payload?.message);
                   navigate("/")
                   
                  }else{
                    toast.error("Old Password is Incorrect");
                  }

                  }catch (error){
                     console.log(error, "errorrrr")
                  }
                }}>
                 {({ values, handleChange, handleSubmit}) => (

                
                <Form className="change-password-form px-5">
                  <Row className="mb-4">
                    <Form.Group as={Col} >
                      <Form.Label>Old Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Current Password"
                        name="currentPassword"
                        as={Form.Control}
                        onChange={handleChange}
                        value={values.currentPassword}
                      />
                      <ErrorMessage  name="currentPassword" component="div"/>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} >
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        as={Form.Control}
                        onChange={handleChange}
                        value={values.password}
                      />
                       <ErrorMessage  name="password" component="div"/>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} >
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        name="confirmpassword"
                        as={Form.Control}
                        onChange={handleChange}
                        value={values.confirmpassword}
                      />
                      <ErrorMessage name="confirmpassword" component="div"/>
                    </Form.Group>
                  </Row>
                  <Form.Group
                    className="mt-4  mb-5 model-btn text-center"
                    controlId="formGridAddress2"
                  >
                    <div class="cmn-btn">
                      <button type="button" onClick={handleSubmit}>SAVE</button>
                    </div>
                  </Form.Group>
                </Form>
                 )}
                 </Formik>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
