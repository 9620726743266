import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ChangeOrderStatus, getOrderById } from "../redux/actions/adminAction";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import Logo from "../Assets/Images/Profile-icon.png";


// const validationSchema = yup.object({
//   orderNumber: yup.string().required("Order number is required"),
//   noOfPackages: yup.number().required("Number of packages is required").positive().integer(),
//   customerName: yup.string().required("Customer name is required"),
//   address: yup.string().required("Address is required"),
//   deliverBy: yup.date().required("Deliver by date is required"),
//   message: yup.string().required("Message is required")
// });

export default function OrderView() {
  const [orderdetails, setorderdetails] = useState([]);
  const [flag, setflag] = useState(true);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  // const initialvalues = {
  //   orderNumber:"",
  //   noOfPackages:"",
  //   customerName:"",
  //   address:"",
  //   deliverBy:"",
  //   message:""
  // }

  const location = useLocation();
  console.log(location);
  const dispatch = useDispatch();

  useEffect(() => {
    const getdata = async () => {
      const data = await dispatch(
        getOrderById({ orderId: location?.state?.id })
      );
      console.log(data);
      if (data?.payload?.status === 200) {
        setorderdetails(data?.payload?.data);
      }
    };
    getdata();
  }, [flag]);

  const handleordersstatus = async (status) => {
    const data = await dispatch(
      ChangeOrderStatus({ orderId: orderdetails?._id, status: status })
    );
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.message);
      setflag(!flag);
    }else{
      toast.error(data?.payload?.message);
    }
  };



  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Order View</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/OrderManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={8}>
            <div className="user-profile-main">
              <Row>
                {/* <Formik initialValues={{ initialvalues }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                 console.log(values);
                }}
                >
      
                {({ values, handleChange, handleSubmit }) => (
                    <Form>      */}
                <Col lg={12}>
                  <Figure className="d-flex">
                    {/* <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src={
                        orderdetails?.userId?.profile_image
                          ? `https://just-in-api.bosselt.com${orderdetails?.userId?.profile_image}`
                          : Logo
                      }
                    /> */}
                    <Figure.Caption>
                      <div className="customer-form-new customer-form-inner p-0 border-none mb-4">
                        <div className="">
                          <h3>Order info</h3>
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Order Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={orderdetails?.orderId}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>No of Packages </Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Type Email Here"
                                  value={orderdetails?.order_quantity}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Customer Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={orderdetails?.userId?.full_name}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Email Here"
                                  value={
                                    orderdetails?.addressId?.address
                                      ? orderdetails?.addressId?.address
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Deliver by</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={
                                    orderdetails?.date
                                      ? new Date(orderdetails.date)
                                          .toISOString()
                                          .slice(0, 10)
                                          .replace(/-/g, "/")
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Instructons</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  value={
                                    orderdetails?.instructions
                                      ? orderdetails?.instructions
                                      : "N/A"
                                  }
                                  rows={3}
                                />
                              </Form.Group>
                            </Col>
                            {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                          </Row>
                        </div>
                      </div>
                    </Figure.Caption>
                  </Figure>
                </Col>
                {/* </Form>
                  )}
                </Formik> */}
              </Row>
            </div>
          </Col>
          <Col lg={4}>
            {" "}
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>Order status</h2>
                <Form.Select
                  aria-label="Default select example"
                  value={orderdetails?.order_status}
                  onChange={(e) => handleordersstatus(e.target.value)}
                >
                  <option>Order Pending</option>
                  <option value="0">Order Placed </option>
                  <option value="1">Order Confirmed</option>
                  <option value="2">Order Shipped</option>
                  <option value="3">Out For Delivery</option>
                  <option value="4">Order Delivered</option>
                  <option value="5">Order Cancelled</option>
                </Form.Select>
              </div>
            </div>
            <div className="product-overview-right mt-4">
              <div className="product-overview-box">
                <div className="order-list-item">
                  {/* <h2>Package 1</h2> */}
                  {/* <div className="order-size-pack">
                    <img src={require("../Assets/Images/order-img.png")} />
                    <div className="">
                      <div>
                        <b>Petrol</b>
                      </div>
                      <div>5 L</div>
                      <div>Qty: 2</div>
                    </div>
                  </div> */}
                </div>
                <div className="order-list-item">
                  <h2>Petrol</h2>
                  <div className="order-size-pack">
                    <img src={`https://just-in-api.bosselt.com/${orderdetails?.productId?.image}`}/>
                    <div className="">
                      <div>
                        <b>Qty:{orderdetails?.productId?.quantity}</b>
                      </div>
                      <strong><div>Price:{orderdetails?.productId?.price}</div></strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          {/* <Col lg={12} className="d-flex justify-content-end mt-2 mb-3">
            {" "}
            <div className="cmn-btn mt-4 mb-3">
              <Link to="#">Update</Link>
            </div>
          </Col> */}
        </Row>
      </Container>
    </Layout>
  );
}
