import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import { editProduct, getProductbyId } from "../redux/actions/adminAction";
import { toast } from "react-toastify";

export default function ViewProduct() {
  const navigate = useNavigate();
  const BASE_URL = "https://just-in-api.bosselt.com";
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.productData.details);
  console.log(data, "data");

  useEffect(() => {
    dispatch(getProductbyId(id));
  }, [id]);

  const [name, setName] = useState();
  const [nameerr, setNameerr] = useState();
  const [desc, setdesc] = useState();
  const [descerr, setdescerr] = useState();
  const [price, setprice] = useState();
  const [priceerr, setpriceerr] = useState();
  const [qty, setqty] = useState();
  const [qtyerr, setqtyerr] = useState();
  const [img, setimg] = useState();
  const [imgerr, setimgerr] = useState();
  const [prev, setPrev] = useState();

  useEffect(() => {
    setName(data?.data?.product_name);
    setdesc(data?.data?.desc);
    setprice(data?.data?.price);
    setqty(data?.data?.quantity);
    setimg(data?.data?.image);
    // setPrev(data?.data?.image)
  }, [data]);

  const handlesave = async () => {
    let isvalid = true;
    if (!name) {
      setNameerr("Required");
      isvalid = false;
    }
    if (!desc) {
      setdescerr("Required");
      isvalid = false;
    }
    if (!price) {
      setpriceerr("Required");
      isvalid = false;
    } else if (!/^\d+$/.test(price)) {
      setpriceerr("Please enter a valid price");
      isvalid = false;
    } else {
      setpriceerr("");
    }
  

    if (!qty) {
      setqtyerr("Required");
      isvalid = false;
    } 
   else {
      setqtyerr("");
    }

    if (!img) {
      setimgerr("Required");
      isvalid = false;
    }
    if (isvalid) {
      let formdata = new FormData();
      formdata.append("product_name", name);
      formdata.append("desc", desc);
      formdata.append("price", price);
      formdata.append("quantity", qty);
      formdata.append("image", img);
      formdata.append("productId", id);

      await dispatch(editProduct(formdata)).then((res) => {
        console.log(res, "res");
        if (res?.payload?.success) {
          toast.success(res?.payload?.message);
          navigate("/ProductManagement");
        } else {
          toast.error("Something went wrong");
        }
      });
    }
  };

  console.log(img, "img");

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>View Product</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/ProductManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={8}>
            <div className="customer-form-new mb-4">
              <div className="customer-form-inner">
                <h5>Product Info</h5>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Product name</Form.Label>
                      <Form.Control
                        value={name}
                        name="name"
                        onChange={(e) => {
                          setName(e.target.value);
                          setNameerr("");
                        }}
                        type="text"
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">{nameerr}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        value={desc}
                        name="desc"
                        onChange={(e) => {
                          setdesc(e.target.value);
                          setdescerr("");
                        }}
                        as="textarea"
                        rows={3}
                      />
                      <Form.Label className="text-danger">{descerr}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        value={price}
                        name="price"
                        onChange={(e) => {
                          setprice(e.target.value);
                          setpriceerr("");
                        }}
                        type="text"
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">
                        {priceerr}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Quantity</Form.Label>
                      <Form.Control
                        value={qty}
                        name="qty"
                        onChange={(e) => {
                          setqty(e.target.value);
                          setqtyerr("");
                        }}
                        type="text"
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">{qtyerr}</Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="customer-form-new mb-4">
              <div className="customer-form-inner">
                <h5>Add Image </h5>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Product Image</Form.Label>
                      <div className="img-upld-bg">
                        <p>
                          Drop your imager here, or browse
                          <br />
                          Jpeg, png are allowed
                        </p>
                        <Form.Control
                          onChange={(e) => {
                            setimg(e.target.files[0]);
                            setimgerr("");
                            setPrev(URL.createObjectURL(e.target.files[0]));
                          }}
                          type="file"
                          placeholder="Type Here"
                        />
                        <img
                        
                          src={prev ? prev : `${BASE_URL}/${img}`}
                        />
                        <Form.Label className="text-danger">
                          {imgerr}
                        </Form.Label>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={4}> </Col>
          <Col lg={12} className="d-flex justify-content-end mt-2 mb-3">
            {" "}
            <div onClick={handlesave} className="cmn-btn mt-4 mb-3">
              <Link to="#">Update</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
