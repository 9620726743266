import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { adminApi } from "../../services/adminapi";


export const adminLogin = createAsyncThunk("Login", async (details) => {
    try {
        const { data } = await adminApi.post("/auth/login", details);
        console.log(data);
    
        if (data?.success) {
          toast.success(data?.message);
          setTimeout(() => {
            toast.dismiss();
           window.location.href = "/Dashboard";
          }, 1500);
          sessionStorage.setItem("token", data?.data?.token);
          
        } else {
          toast.error(data?.message);
        }
    
        return data;
      } catch (error) {
        toast.error(error?.response?.data?.message);

       
      }


});
