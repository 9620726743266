import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { OtpVerify } from "../redux/actions/adminAction";


const validationSchema = yup.object().shape({
  Otp: yup
    .string()
    .required(<span style={{ color: 'red' }}>Required</span>)
    .min(4, <span style={{ color: 'red' }}>OTP must be atleast 4 characters</span>),
});

export default function LoginOtp() {

  const diispatch = useDispatch();
  const navigate = useNavigate();
  const loaction = useLocation();
  console.log(loaction);


  
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Enter OTP</h2>
                <Formik
                  initialValues={{ Otp: "" }}
                  validationSchema={validationSchema}
                  onSubmit={ async (values) => {
                    console.log(values);

                    try{
                     const data = await diispatch(OtpVerify({otp:values.Otp, email:loaction.state.email} ));
                     console.log(data,"data");
                     if(data?.payload?.success){
                      toast.success(data?.payload?.message)
                      navigate('/ChangePasswordtwo', { state: { email:loaction?.state?.email} });
                     }else{
                      toast.error('Incorrect OTP');
                     }
                    }catch (error){
                      console.log(error,"hlifylfpfvhbjklgvbyihc")
                    }
                  }}
                >
                  {({ values, handleSubmit, handleChange }) => (
                    <Form>
                      <div className="form-set">
                        <Form.Group
                          className="mb-3"
                        >
                          <Form.Label>Enter OTP</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Type Here"
                            name="Otp"
                            as={Form.Control}
                            onChange={handleChange}
                            value={values.Otp}
                          />
                          <ErrorMessage name="Otp" component="div"/>
                        </Form.Group>
                      </div>
                      <Link
                        // to="/"
                        variant="primary"
                        type="submit"
                        className="submit"
                        onClick={handleSubmit}
                      >
                        Log In
                      </Link>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
