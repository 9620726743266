import { createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { getProductbyId, getProducts } from "../actions/adminAction";



export const productSlice= createSlice({
    name:"productSlice",
    initialState:{
        list:"",
        details:""
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getProducts.fulfilled,(state,action)=>{
            if (action?.payload?.success) {
                state.list = action.payload;
              } else {
                toast.error(action?.payload?.message);
              }
        })
        builder.addCase(getProductbyId.fulfilled,(state,action)=>{
            if (action?.payload?.success) {
                state.details = action.payload;
              } else {
                toast.error(action?.payload?.message);
              }
        })
    }
})