import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeOrderStatus,
  dowanloadOrdersCsv,
  getOrdersListing,
} from "../redux/actions/adminAction";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

export default function OrderManagement() {
  const [page, setpages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);
  const [Ordersdata, setOdersdata] = useState([]);
  const [pagi, setpagi] = useState("");
  const [orderStatus, setOrdetStatus] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getdata = async () => {
      const data = await dispatch(
        getOrdersListing({
          limit: limit,
          search: search,
          page: page,
          status: orderStatus,
        })
      );
      console.log(data);
      if (data?.payload?.status === 200) {
        setOdersdata(data?.payload?.data?.allOrders);
        setpagi(data?.payload?.data);
      }
    };
    getdata();
  }, [limit, search, page, orderStatus, flag]);

  const handlesearch = (e) => {
    setsearch(e.target.value);
  };

  const handlelimit = (e) => {
    setlimit(e.target.value);
  };

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(dowanloadOrdersCsv());
      console.log(response);
      const allData = response?.payload?.data?.allOrders;
      const ws = XLSX.utils.json_to_sheet(allData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  const handleorder = (order) => {
    navigate("/OrderView", {
      state: {
        id: order,
      },
    });
  };

  const handleordersstatus = async (id, status) => {
    const data = await dispatch(
      ChangeOrderStatus({ orderId: id, status: status })
    );
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.message);
      setflag(!flag);
    } else {
      toast.error(data?.payload?.message);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Order Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={handlesearch}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setOrdetStatus(e.target.value);
                  }}
                >
                  <option>Order status</option>
                  <option value="0">Order Placed </option>
                  <option value="1">Order Confirmed</option>
                  <option value="2">Order Shipped</option>
                  <option value="3">Out For Delivery</option>
                  <option value="4">Order Delivered</option>
                  <option value="5">Order Cancelled</option>
                </Form.Select>
              </Col>
              <Col
                xxl={7}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn" onClick={fetchDataAndExportToExcel}>
                  <Link to="#">DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {pagi?.currentPage ? 1 + (pagi?.currentPage - 1) * limit : 0} -{" "}
                {pagi?.currentPage
                  ? Math.min(pagi?.currentPage * limit, pagi?.totalCount)
                  : 0}{" "}
                of {pagi?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={handlelimit}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Order No</th>
                <th>No of Packages</th>
                <th>Customer name</th>
                <th>Address</th>
                {/* <th>Status</th> */}
                <th>Order Status</th>
              </tr>
            </thead>
            {Ordersdata?.map((details, index) => {
              console.log(details);
              const serialNumber =
                ((page == "1" ? 1 : page) - 1) * limit + index + 1;
              return (
                <tbody>
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleorder(details?._id)}
                      >
                        <b>{details?.orderId}</b>
                      </p>
                    </td>
                    <td>{details?.order_quantity}</td>
                    <td>{details?.userId?.full_name}</td>
                    <td>
                      {details?.addressId?.address
                        ? details?.addressId?.address
                        : "N/A"}
                    </td>

                    <td>
                      <div className="select-box">
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handleordersstatus(details?._id, e.target.value);
                          }}
                          value={details?.order_status}
                        >
                          <option>Pending</option>
                          <option value="0">Order Placed </option>
                          <option value="1">Order Confirmed</option>
                          <option value="2">Order Shipped</option>
                          <option value="3">Out For Delivery</option>
                          <option value="4">Order Delivered</option>
                          <option value="5">Order Cancelled</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </div>
      </Container>

      <div className="table-pagi">
        <Pagination
          totalstuff={pagi?.totalCount}
          limit={limit}
          setpages={setpages}
        />
      </div>
    </Layout>
  );
}
